import axios from 'axios';
const moment = require('moment');

const locations = [
  // {
  // 	id: "california",
  // 	name: "california",
  // 	location : "California",
  // 	countryCode : "US",
  // 	countryName : "United State",
  // 	chartColor : "#2196f3"
  // },
  // {
  // 	id: "singapore",
  // 	name: "singapore",
  // 	location : "Singapore",
  // 	countryCode : "SG",
  // 	countryName : "Singapore",
  // 	chartColor : "#673ab7"
  // },
  // {
  // 	id: "frankfurt",
  // 	name: "frankfurt",
  // 	location : "Frankfurt",
  // 	countryCode : "DE",
  // 	countryName : "Germany",
  // 	chartColor : "#ffc107"
  // },
  // {
  // 	id: "sydney",
  // 	name: "sydney",
  // 	location : "Sydney",
  // 	countryCode : "AU",
  // 	countryName : "Australia",
  // 	chartColor : "#4caf50"
  // },
  {
    id: 'toronto',
    name: 'toronto',
    location: 'Toronto',
    countryCode: 'CA',
    countryName: 'Canada',
    chartColor: '#d52c1e',
    isActive: false,
  },

  // ! NEW REGIONS FROM AWS
  {
    id: 'virginia',
    name: 'virginia',
    location: 'Virginia',
    aws_region_id: 'us-east-1',
    countryCode: 'US',
    countryName: 'United State',
    chartColor: '#2196f3',
    isActive: true,
  },
  {
    id: 'ohio',
    name: 'ohio',
    location: 'Ohio',
    aws_region_id: 'us-east-2',
    countryCode: 'US',
    countryName: 'United State',
    chartColor: '#0057e7',
    isActive: true,
  },
  {
    id: 'california',
    name: 'california',
    location: 'California',
    aws_region_id: 'us-west-1',
    countryCode: 'US',
    countryName: 'United State',
    chartColor: '#a8e6cf',
    isActive: true,
  },
  {
    id: 'oregon',
    name: 'oregon',
    location: 'Oregon',
    aws_region_id: 'us-west-2',
    countryCode: 'US',
    countryName: 'United State',
    chartColor: '#ff77ff',
    isActive: true,
  },
  {
    id: 'ireland',
    name: 'ireland',
    location: 'Ireland',
    aws_region_id: 'eu-west-1',
    countryCode: 'IE',
    countryName: 'Ireland',
    chartColor: '#22ffaa',
    isActive: true,
  },
  {
    id: 'london',
    name: 'london',
    location: 'London',
    aws_region_id: 'eu-west-2',
    countryCode: 'GB',
    countryName: 'United Kingdom',
    chartColor: '#996666',
    isActive: true,
  },
  {
    id: 'paris',
    name: 'paris',
    location: 'Paris',
    aws_region_id: 'eu-west-3',
    countryCode: 'FR',
    countryName: 'France',
    chartColor: '#c8241b',
    isActive: true,
  },
  {
    id: 'frankfurt',
    name: 'frankfurt',
    location: 'Frankfurt',
    aws_region_id: 'eu-central-1',
    countryCode: 'DE',
    countryName: 'Germany',
    chartColor: '#ffc107',
    isActive: true,
  },
  {
    id: 'singapore',
    name: 'singapore',
    location: 'Singapore',
    aws_region_id: 'ap-southeast-1',
    countryCode: 'SG',
    countryName: 'Singapore',
    chartColor: '#673ab7',
    isActive: true,
  },
  {
    id: 'sydney',
    name: 'sydney',
    location: 'Sydney',
    aws_region_id: 'ap-southeast-2',
    countryCode: 'AU',
    countryName: 'Australia',
    chartColor: '#fbb40c',
    isActive: true,
  },
  {
    id: 'tokyo',
    name: 'tokyo',
    location: 'Tokyo',
    aws_region_id: 'ap-northeast-1',
    countryCode: 'JP',
    countryName: 'Japan',
    chartColor: '#81607f',
    isActive: true,
  },
  {
    id: 'seoul',
    name: 'seoul',
    location: 'Seoul',
    aws_region_id: 'ap-northeast-2',
    countryCode: 'KR',
    countryName: 'South Korea',
    chartColor: '#097f8c',
    isActive: true,
  },
  {
    id: 'mumbai',
    name: 'mumbai',
    location: 'Mumbai',
    aws_region_id: 'ap-south-1',
    countryCode: 'IN',
    countryName: 'India',
    chartColor: '#ff9932',
    isActive: true,
  },
  {
    id: 'montreal',
    name: 'montreal',
    location: 'Montreal',
    aws_region_id: 'ca-central-1',
    countryCode: 'CA',
    countryName: 'Canada',
    chartColor: '#d52c1e',
    isActive: true,
  },
  {
    id: 'stockholm',
    name: 'stockholm',
    location: 'Stockholm',
    aws_region_id: 'eu-north-1',
    countryCode: 'SE',
    countryName: 'Sweden',
    chartColor: '#ffdd00',
    isActive: true,
  },
  {
    id: 'osaka',
    name: 'osaka',
    location: 'Osaka',
    aws_region_id: 'ap-northeast-3',
    countryCode: 'JP',
    countryName: 'Japan',
    chartColor: '#00505c',
    isActive: true,
  },
  {
    id: 'saopaulo',
    name: 'saopaulo',
    location: 'São Paulo',
    aws_region_id: 'me-south-1',
    countryCode: 'BR',
    countryName: 'Brazil',
    chartColor: '#00225c',
    isActive: true,
  },

  // ? Additional AWS REGIONS
  // {
  // 	id: "bahrain",
  // 	name: "bahrain",
  // 	location: "Bahrain",
  // 	aws_region_id: "sa-east-1",
  // 	countryCode: "BH",
  // 	countryName: "Bahrain",
  // 	chartColor: "#fb8500",
  // 	isActive: true
  // },
  // {
  // 	id: "capetown",
  // 	name: "capetown",
  // 	location: "Cape Town",
  // 	aws_region_id: "af-south-1",
  // 	countryCode: "ZA",
  // 	countryName: "South Africa",
  // 	chartColor: "#ffb703",
  // 	isActive: true
  // },
  // {
  // 	id: "hongkong",
  // 	name: "hongkong",
  // 	location: "Hong Kong",
  // 	aws_region_id: "ap-east-1",
  // 	countryCode: "HK",
  // 	countryName: "Hong Kong",
  // 	chartColor: "#78CAD2",
  // 	isActive: true
  // },
  // {
  // 	id: "milan",
  // 	name: "milan",
  // 	location: "Milan",
  // 	aws_region_id: "eu-south-1",
  // 	countryCode: "IT",
  // 	countryName: "Italy",
  // 	chartColor: "#E2B4BD",
  // 	isActive: true
  // },

  // {
  //     id: "us-east-2",
  //     name: "ohio",
  //     location : "Ohio",
  //     countryCode : "US",
  //     countryName : "United State",
  //     chartColor : "#28d102"
  // },
  // {
  //     id: "us-west-1",
  //     name: "california",
  //     location : "California",
  //     countryCode : "US",
  //     countryName : "United State",
  //     chartColor : "#f42a04"
  // },
  // {
  // 	id: "ap-northeast-2",
  // 	name: "seoul",
  // 	location : "Seoul",
  // 	countryCode : "KR",
  // 	countryName : "South-korea",
  // 	chartColor : "#673ab7"
  // },
  // {
  // 	id: "ap-southeast-2",
  // 	name: "sydney",
  // 	location : "Sydney",
  // 	countryCode : "AU",
  // 	countryName : "Australia",
  // 	chartColor : "#28d101"
  // },
  // {
  // 	id: "eu-west-3",
  // 	name: "paris",
  // 	location : "Paris",
  // 	countryCode : "FR",
  // 	countryName : "France",
  // 	chartColor : "#FF9800"
  // },

  // {
  //     id: "us-east-2",
  //     name: "ohio",
  //     location : "Ohio",
  //     countryCode : "US",
  //     countryName : "United State",
  //     chartColor : "#28d102"
  // },
  // {
  //     id: "us-west-1",
  //     name: "california",
  //     location : "California",
  //     countryCode : "US",
  //     countryName : "United State",
  //     chartColor : "#f42a04"
  // },
  // {
  // 	id: "ap-northeast-2",
  // 	name: "seoul",
  // 	location : "Seoul",
  // 	countryCode : "KR",
  // 	countryName : "South-korea",
  // 	chartColor : "#673ab7"
  // },
  // {
  // 	id: "ap-southeast-2",
  // 	name: "sydney",
  // 	location : "Sydney",
  // 	countryCode : "AU",
  // 	countryName : "Australia",
  // 	chartColor : "#28d101"
  // },
  // {
  // 	id: "eu-west-3",
  // 	name: "paris",
  // 	location : "Paris",
  // 	countryCode : "FR",
  // 	countryName : "France",
  // 	chartColor : "#FF9800"
  // },

  // {
  // 	id: "eu-west-2",
  // 	name: "london",
  // 	location : "London",
  // 	countryCode : "GB",
  // 	countryName : "United kingdom",
  // 	chartColor : "#03a9f4"
  // },

  // {
  // 	id: "sa-east-1",
  // 	name: "saopaulo",
  // 	location : "São Paulo",
  // 	countryCode : "BR",
  // 	countryName : "Brazil",
  // 	chartColor : "#229e45"
  // },
];
const opsgenieRegions = [
  {
    id: 'us',
    name: 'US',
  },
  {
    id: 'eu',
    name: 'EU',
  },
];

const incidentEvents = [
  {
    id: 'INVESTIGATING',
    name: 'Investigating',
    status: 'danger',
  },
  {
    id: 'IDENTIFIED',
    name: 'Identified',
    status: 'warning',
  },
  {
    id: 'MONITORING',
    name: 'Monitoring',
    status: 'warning',
  },
  {
    id: 'RESOLVED',
    name: 'Resolved',
    status: 'success',
  },
];

const maintenanceEvents = [
  {
    id: 'SCHEDULED',
    name: 'Scheduled',
    status: 'danger',
  },
  {
    id: 'INPROGRESS',
    name: 'In Progress',
    status: 'warning',
  },
  {
    id: 'VERIFING',
    name: 'Verifing',
    status: 'warning',
  },
  {
    id: 'COMPLETED',
    name: 'Completed',
    status: 'success',
  },
];

const alertChannels = [
  {
    name: 'Email',
    description: 'Get alerts when a check fails and recovers in your inbox.',
    link: '/settings/alert/channels/new/email',
    icon: 'email',
    bigIcon: true,
    isNew: false,
  },
  {
    name: 'Slack',
    description:
      'Get detailed failure and recovery reports in your Slack channel as they happen.',
    link: '/settings/alert/channels/new/slack',
    icon: 'slack',
    bigIcon: true,
    isNew: false,
  },
  {
    name: 'SMS',
    description:
      'Get a text message on your phone on each failure and recovery.',
    link: '/settings/alert/channels/new/sms',
    icon: 'sms',
    bigIcon: true,
    isNew: false,
  },
  {
    name: 'Webhooks',
    description:
      'Get a custom formatted failure / recovery message on any endpoint you want.',
    link: '/settings/alert/channels/new/webhook',
    icon: 'webhooks',
    bigIcon: true,
    isNew: false,
  },
  {
    name: 'Telegram',
    description:
      'Get a custom formatted failure / recovery message on any telegram group/profile you want.',
    link: '/settings/alert/channels/new/telegram',
    icon: 'telegram',
    bigIcon: true,
    isNew: moment() < moment('2021-08-24').add(30, 'days'),
  },
  {
    name: 'PagerDuty',
    description: 'Send failure or recovery messages to any Pagerduty channel.',
    link: '/settings/alert/channels/new/pagerduty',
    icon: 'pagerduty',
    bigIcon: true,
    isNew: moment() < moment('2021-09-04').add(30, 'days'),
  },
  {
    name: 'Opsgenie',
    description: 'Create and close alerts in any Opsgenie team.',
    link: '/settings/alert/channels/new/opsgenie',
    icon: 'opsgenie',
    bigIcon: true,
    isNew: moment() < moment('2021-08-26').add(30, 'days'),
  },
  {
    name: 'Discord',
    description:
      'Get detailed failure and recovery alerts in any Discord channel.',
    link: '/settings/alert/channels/new/discord',
    icon: 'discord',
    bigIcon: true,
    isNew: moment() < moment('2021-08-27').add(30, 'days'),
  },
];

const statusPageAlertChannels = [
  {
    id: 'email',
    name: 'Email',
    description: 'Get alerts when a check fails and recovers in your inbox.',
    link: '/settings/alert/channels/new/email',
    icon: 'email',
    bigIcon: true,
    isNew: false,
  },
  {
    id: 'slack',
    name: 'Slack',
    description:
      'Get detailed failure and recovery reports in your Slack channel as they happen.',
    link: '/settings/alert/channels/new/slack',
    icon: 'slack',
    bigIcon: true,
    isNew: false,
  },
  {
    id: 'sms',
    name: 'SMS',
    description:
      'Get a text message on your phone on each failure and recovery.',
    link: '/settings/alert/channels/new/sms',
    icon: 'sms',
    bigIcon: true,
    isNew: false,
  },
  {
    id: 'webhook',
    name: 'Webhook',
    description:
      'Get a custom formatted failure / recovery message on any endpoint you want.',
    link: '/settings/alert/channels/new/webhook',
    icon: 'webhooks',
    bigIcon: true,
    isNew: false,
  },
  // {
  // 	id : 'telegram',
  // 	name: 'Telegram',
  // 	description: 'Get a custom formatted failure / recovery message on any telegram group/profile you want.',
  // 	link: '/settings/alert/channels/new/telegram',
  // 	icon : 'telegram',
  // 	bigIcon : true,
  // 	isNew : moment() < moment('2021-08-24').add(30 , 'days')
  // },
  {
    id: 'discord',
    name: 'Discord',
    description:
      'Get detailed failure and recovery alerts in any Discord channel.',
    link: '/settings/alert/channels/new/discord',
    icon: 'discord',
    bigIcon: true,
    isNew: moment() < moment('2021-08-27').add(30, 'days'),
  },
  {
    id: 'rss',
    name: 'RSS & Atom',
    description:
      'Get detailed failure and recovery alerts in on Rss Or Atom format.',
    link: '/settings/alert/channels/new/rss',
    icon: 'rss',
    bigIcon: true,
    isNew: moment() < moment('2021-08-27').add(30, 'days'),
  },
];

export const countries = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas ',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory ',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands ',
  CF: 'Central African Republic ',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands ',
  CO: 'Colombia',
  KM: 'Comoros ',
  CD: 'Congo (the Democratic Republic of the)',
  CG: 'Congo ',
  CK: 'Cook Islands ',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  CI: "Côte d'Ivoire",
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic ',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands  [Malvinas]',
  FO: 'Faroe Islands ',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories ',
  GA: 'Gabon',
  GM: 'Gambia ',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See ',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran (Islamic Republic of)',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea (the Democratic People's Republic of)",
  KR: 'Korea (the Republic of)',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic ",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands ',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia (Federated States of)',
  MD: 'Moldova (the Republic of)',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands ',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger ',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands ',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines ',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  MK: 'Republic of North Macedonia',
  RO: 'Romania',
  RU: 'Russian Federation ',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan ',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands ',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates ',
  GB: 'United Kingdom of Great Britain and Northern Ireland ',
  UM: 'United States Minor Outlying Islands ',
  US: 'United States of America ',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Bolivarian Republic of)',
  VN: 'Viet Nam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AX: 'Åland Islands',
};

export const taxabilityReasons = [
  {
    id: 'standard_rated',
    description: 'Taxed at the standard rate.',
    explanation:
      'This item is subject to the standard tax rate applicable in your region.',
  },
  {
    id: 'reduced_rated',
    description: 'Taxed at a reduced rate.',
    explanation:
      'This item is taxed at a reduced rate, which may be lower than the standard tax rate in your region.',
  },
  {
    id: 'zero_rated',
    description:
      'The transaction is taxed at a special rate of 0% or the transaction is exempt (but these exempt transactions still let you deduct the “input VAT” paid on your business purchases).',
    explanation:
      'This transaction is taxed at a special rate of 0% or is exempt from tax. Exempt transactions still allow you to deduct any tax paid on your business purchases.',
  },
  {
    id: 'reverse_charge',
    description:
      'No tax is applied as it is the responsibility of the buyer to account for tax in this case.',
    explanation:
      "No tax is applied to this purchase because it is the buyer's responsibility to account for the tax in this case.",
  },
  {
    id: 'customer_exempt',
    description: 'No tax is applied as the customer is exempt from tax.',
    explanation: 'No tax is applied because the customer is exempt from tax.',
  },
  {
    id: 'product_exempt',
    description: 'The product or service is nontaxable or exempt from tax.',
    explanation:
      'This product or service is not subject to tax and is considered non-taxable or exempt.',
  },
  {
    id: 'product_exempt_holiday',
    description:
      'The product or service is not taxed due to a sales tax holiday.',
    explanation:
      'The product or service is not taxed due to a sales tax holiday, typically a temporary period of exemption.',
  },
  {
    id: 'portion_standard_rated',
    description: 'A portion of the price is taxed at the standard rate.',
    explanation:
      'Only a portion of the price is subject to tax, and it is taxed at the standard rate applicable in your region.',
  },
  {
    id: 'portion_reduced_rated',
    description: 'A portion of the price is taxed at a reduced rate.',
    explanation:
      'Only a portion of the price is subject to tax, and it is taxed at a reduced rate, which may be lower than the standard rate.',
  },
  {
    id: 'portion_product_exempt',
    description: 'A portion of the price is exempt from tax.',
    explanation:
      'A portion of the price is exempt from tax, while the rest may be subject to tax or taxed at a different rate.',
  },
  {
    id: 'taxable_basis_reduced',
    description: 'A reduced amount of the price is subject to tax.',
    explanation:
      'A reduced amount of the price is subject to tax, typically taxed at a rate lower than the standard rate.',
  },
  {
    id: 'not_collecting',
    description:
      'No tax is collected either because you are not registered to collect tax in this jurisdiction, or because the non-taxable product tax code (txcd_00000000) was used.',
    explanation:
      'No tax is collected for this purchase. This could be because the seller is not registered to collect tax in your region or a non-taxable product tax code was used.',
  },
  {
    id: 'not_subject_to_tax',
    description: 'No tax is imposed on this transaction.',
    explanation:
      'This transaction is not subject to any tax, and no tax is imposed on your purchase.',
  },
  {
    id: 'not_supported',
    description:
      'No tax applied. Stripe doesn’t support this jurisdiction, territory, or product.',
    explanation:
      'No tax is applied because the tax regulations in this jurisdiction, territory, or for this particular product do not require the imposition of tax.',
  },
  {
    id: 'proportionally_rated',
    description:
      'The shipping cost tax rate is calculated as a weighted average of the other line items’ rates, weighted by their amounts.',
    explanation:
      'The tax rate applied to shipping costs is calculated as a weighted average of the tax rates for other line items, taking into account their respective amounts.',
  },
];

export function getHttpCodeDescription(statusCode) {
  const httpCodesDescriptions = {
    100: 'Continue',
    101: 'Switching Protocols',
    102: 'Processing (WebDAV; RFC 2518)',
    103: 'Early Hints (RFC 8297)',
    200: 'OK',
    201: 'Created',
    202: 'Accepted',
    203: 'Non-Authoritative Information (since HTTP/1.1)',
    204: 'No Content',
    205: 'Reset Content',
    206: 'Partial Content (RFC 7233)',
    207: 'Multi-Status (WebDAV; RFC 4918)',
    208: 'Already Reported (WebDAV; RFC 5842)',
    226: 'IM Used (RFC 3229)',
    300: 'Multiple Choices',
    301: 'Moved Permanently',
    302: 'Found (Previously Moved temporarily )',
    303: 'See Other (since HTTP/1.1)',
    304: 'Not Modified (RFC 7232)',
    305: 'Use Proxy (since HTTP/1.1)',
    306: 'Switch Proxy',
    307: 'Temporary Redirect (since HTTP/1.1)',
    308: 'Permanent Redirect (RFC 7538)',
    // "404": "error on Wikipedia",
    400: 'Bad Request',
    401: 'Unauthorized (RFC 7235)',
    402: 'Payment Required',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
    406: 'Not Acceptable',
    407: 'Proxy Authentication Required (RFC 7235)',
    408: 'Request Timeout',
    409: 'Conflict',
    410: 'Gone',
    411: 'Length Required',
    412: 'Precondition Failed (RFC 7232)',
    413: 'Payload Too Large (RFC 7231)',
    414: 'URI Too Long (RFC 7231)',
    415: 'Unsupported Media Type (RFC 7231)',
    416: 'Range Not Satisfiable (RFC 7233)',
    417: 'Expectation Failed',
    418: "I'm a teapot (RFC 2324, RFC 7168)",
    421: 'Misdirected Request (RFC 7540)',
    422: 'Unprocessable Entity (WebDAV; RFC 4918)',
    423: 'Locked (WebDAV; RFC 4918)',
    424: 'Failed Dependency (WebDAV; RFC 4918)',
    425: 'Too Early (RFC 8470)',
    426: 'Upgrade Required',
    428: 'Precondition Required (RFC 6585)',
    429: 'Too Many Requests (RFC 6585)',
    431: 'Request Header Fields Too Large (RFC 6585)',
    451: 'Unavailable For Legal Reasons (RFC 7725)',
    500: 'Internal Server Error',
    501: 'Not Implemented',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
    505: 'HTTP Version Not Supported',
    506: 'Variant Also Negotiates (RFC 2295)',
    507: 'Insufficient Storage (WebDAV; RFC 4918)',
    508: 'Loop Detected (WebDAV; RFC 5842)',
    510: 'Not Extended (RFC 2774)',
    511: 'Network Authentication Required (RFC 6585)',
    // "103": "Checkpoint",
    218: 'This is fine (Apache Web Server)',
    419: 'Page Expired (Laravel Framework)',
    420: 'Method Failure (Spring Framework)',
    // "420": "Enhance Your Calm (Twitter)",
    430: 'Request Header Fields Too Large (Shopify)',
    450: 'Blocked by Windows Parental Controls (Microsoft)',
    498: 'Invalid Token (Esri)',
    // "499": "Token Required (Esri)",
    509: 'Bandwidth Limit Exceeded (Apache Web Server/cPanel)',
    526: 'Invalid SSL Certificate',
    529: 'Site is overloaded',
    530: 'Site is frozen',
    598: '(Informal convention) Network read timeout error',
    440: 'Login Time-out',
    449: 'Retry With',
    // "451": "Redirect",
    444: 'No Response',
    494: 'Request header too large',
    495: 'SSL Certificate Error',
    496: 'SSL Certificate Required',
    497: 'HTTP Request Sent to HTTPS Port',
    499: 'Client Closed Request',
    520: 'Web Server Returned an Unknown Error',
    521: 'Web Server Is Down',
    522: 'Connection Timed Out',
    523: 'Origin Is Unreachable',
    524: 'A Timeout Occurred',
    525: 'SSL Handshake Failed',
    // "526": "Invalid SSL Certificate",
    527: 'Railgun Error',
  };
}
export const statusCodeToReasonPhrase = {
  202: 'Accepted',
  502: 'Bad Gateway',
  400: 'Bad Request',
  409: 'Conflict',
  100: 'Continue',
  201: 'Created',
  417: 'Expectation Failed',
  424: 'Failed Dependency',
  403: 'Forbidden',
  504: 'Gateway Timeout',
  410: 'Gone',
  505: 'HTTP Version Not Supported',
  418: "I'm a teapot",
  419: 'Insufficient Space on Resource',
  507: 'Insufficient Storage',
  500: 'Internal Server Error',
  411: 'Length Required',
  423: 'Locked',
  420: 'Method Failure',
  405: 'Method Not Allowed',
  301: 'Moved Permanently',
  302: 'Moved Temporarily',
  207: 'Multi-Status',
  300: 'Multiple Choices',
  511: 'Network Authentication Required',
  204: 'No Content',
  203: 'Non Authoritative Information',
  406: 'Not Acceptable',
  404: 'Not Found',
  501: 'Not Implemented',
  304: 'Not Modified',
  200: 'OK',
  206: 'Partial Content',
  402: 'Payment Required',
  308: 'Permanent Redirect',
  412: 'Precondition Failed',
  428: 'Precondition Required',
  102: 'Processing',
  103: 'Early Hints',
  426: 'Upgrade Required',
  407: 'Proxy Authentication Required',
  431: 'Request Header Fields Too Large',
  408: 'Request Timeout',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Long',
  416: 'Requested Range Not Satisfiable',
  205: 'Reset Content',
  303: 'See Other',
  503: 'Service Unavailable',
  101: 'Switching Protocols',
  307: 'Temporary Redirect',
  429: 'Too Many Requests',
  401: 'Unauthorized',
  451: 'Unavailable For Legal Reasons',
  422: 'Unprocessable Entity',
  415: 'Unsupported Media Type',
  305: 'Use Proxy',
  421: 'Misdirected Request',
};
export function getLocationList(LOCATION_STATUS = 'active') {
  let response = null;
  switch (LOCATION_STATUS) {
    case 'all':
      response = locations;
      break;
    case 'active':
      response = locations.filter((x) => x.isActive === true);
      break;
    case 'inactive':
      response = locations.filter((x) => x.isActive === false);
      break;
  }

  return response;
}
export function getLocationData(region_server_name) {
  const result = locations.find(({ name }) => name === region_server_name);

  return result;
}

export function getOpsgenieRegionName(ID) {
  const d = opsgenieRegions.find((x) => x.id === ID);

  return typeof d !== 'undefined' ? d.name : null;
}

export function getIncidentEvents(ID) {
  if (typeof ID === 'undefined' || ID === null || ID === '')
    return incidentEvents;

  const d = incidentEvents.find((x) => x.id === ID);

  return typeof d !== 'undefined' ? d.name : null;
}
export function getMaintenanceEvents(ID) {
  if (typeof ID === 'undefined' || ID === null || ID === '')
    return maintenanceEvents;

  const d = maintenanceEvents.find((x) => x.id === ID);

  return typeof d !== 'undefined' ? d.name : null;
}
export function getAlertChannels(ID) {
  if (typeof ID === 'undefined' || ID === null || ID === '')
    return alertChannels;

  const d = alertChannels.find((x) => x.id === ID);

  return typeof d !== 'undefined' ? d.name : null;
}

export function getStatusPageAlertChannels(ID) {
  if (typeof ID === 'undefined' || ID === null || ID === '')
    return statusPageAlertChannels;

  const d = statusPageAlertChannels.find((x) => x.id === ID);

  return typeof d !== 'undefined' ? d.name : null;
}

export function diffDates(from, to) {
  const ms = moment(to).diff(moment(from));
  return moment.duration(ms);
}


export function getMonitorIncidentStatusIcon(status) {
  switch (status) {
    case 'OPERATIONAL':
      return `resolved.png`;
    case 'DEGRADED-PERFORMANCE':
      return `degraded-performance.png`;
    case 'PARTIAL-OUTAGE':
      return `partial-outage.png`;
    case 'MAJOR-OUTAGE':
      return `major-outage.png`;
    case 'UNDER-MAINTENANCE':
      return `under-maintenance.png`;
  }
}

export const DISCORD_WEBHOOK_URL = 'https://discord.com/api/webhooks/1273009954386743469/nz5nB7rk3LdPlJ7k7MrWMV80s0MvcfBaghv3iN3oc9vq0N8gOzZCeFT7bTeDpDXBjqdC'

export async function sendDiscordNotification(input) {
  let messageContent;
  if (input.email) {
    messageContent = {
      content: `New user signup: ${input.email}`,
    };
  } else if (input.monitorName) {
    messageContent = {
      content: `${input.accountName} created a new monitor: ${input.monitorName}`,
    };
  } else if (input.statusPageName) {
    messageContent = {
      content: `${input.accountName} created a new status page: ${input.statusPageName}`,
    };
  }

  try {
    await axios.post(DISCORD_WEBHOOK_URL, messageContent, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
  }
}
