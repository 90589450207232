import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { getItemStorage, setItemStorage } from '../../../../utils/storage';
import { verifySession } from './../../../authentification/SecurityController';
import { Helmet } from 'react-helmet';
// context
import AppContext from '../../../../context/app-context';
import GA from './../../../../components/Tracking/GoogleAnalytics';
//css
// import { TextField, Button } from '@material-ui/core';
import './../auth-interface.css';

import { ReactComponent as Logo } from './../../../../assets/logo/logo.svg';

export default class Signin extends React.Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      signin_email: '',
      signin_email_placeholder: 'Email Address',
      signin_email_validate: true,
      signin_password: '',
      signin_password_placeholder: 'Password',
      signin_password_validate: true,
      signin_response: '',
      signin_response_status: 'warning',
      signin_email_start_tapping: false,
      signin_password_start_tapping: false,
      isLoading: false,
      isConnected: false,
      sessionToken: null,
      email_regex_validator:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange_signinEmail = this.handleChange_signinEmail.bind(this);
    this.handleChange_signinPassword =
      this.handleChange_signinPassword.bind(this);
  }

  handleChange_signinEmail(event) {
    let signin_email_validate = false;
    if (
      typeof event.target.value !== 'undefined' &&
      event.target.value !== '' &&
      event.target.value.match(this.state.email_regex_validator) != null
    ) {
      signin_email_validate = true;
    }
    this.setState({
      signin_email_start_tapping: true,
      signin_email: event.target.value,
      signin_email_validate: signin_email_validate,
    });
  }

  handleChange_signinPassword(event) {
    let signin_password_validate = false;
    if (
      typeof event.target.value !== 'undefined' &&
      event.target.value !== ''
    ) {
      signin_password_validate = true;
    }
    this.setState({
      signin_password_start_tapping: true,
      signin_password: event.target.value,
      signin_password_validate: signin_password_validate,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const {
      signin_email,
      signin_password,
      signin_password_validate,
      signin_email_validate,
    } = this.state;

    this.setState({
      isLoading: true,
    });

    // ! Check the email address field
    if (
      typeof signin_email === 'undefined' ||
      signin_email === null ||
      signin_email_validate === false
    ) {
      this.setState({
        isLoading: false,
        signin_response: 'Email address must have a valid form',
        signin_response_status: 'danger',
      });
      return;
    }

    // ! Check the password field
    if (
      typeof signin_password === 'undefined' ||
      signin_password === null ||
      signin_password_validate === false ||
      signin_password.length < 4
    ) {
      this.setState({
        isLoading: false,
        signin_response:
          'Password too short, try a mix of characters and numbers',
        signin_response_status: 'danger',
      });
      return;
    }

    //post request to api
    let paramsPOST = {
      email: this.state.signin_email,
      password: this.state.signin_password,
    };

    // get invite id if exisite ( pending token )
    const INVITE_TOKEN = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__INVITE_TOKEN}`,
      'string'
    );
    if (
      typeof INVITE_TOKEN !== 'undefined' &&
      INVITE_TOKEN !== null &&
      INVITE_TOKEN !== ''
    ) {
      paramsPOST['inviteId'] = INVITE_TOKEN;
    }

    //post request to api
    await axios
      .post(`${process.env.REACT_APP_API_URL}users/login`, paramsPOST, {
        cancelToken: this.signal.token,
      })
      .then((res) => res.data)
      .then(async (result) => {
        if (typeof result.token !== 'undefined' && result.token !== null) {
          // save token as session in localStorage
          setItemStorage(`${process.env.REACT_APP_AUTH_NAME_STORAGE}`, {
            token: result.token,
          });
          await this.context.login(); // update user session global state
          // save storage : Connected user data
          this.context.setConnectedUserData(result);

          //console.log('result.accounts : ' , result.accounts);
          this.setState(
            {
              isLoading: false,
              signin_response: result.message,
              signin_response_status: 'success',
              sessionToken: result.token,
            },
            async () => {
              if (
                typeof result.accounts !== 'undefined' &&
                result.accounts.length > 0
              ) {
                // Get the current account id  from local storage
                // const currentAccountKey = await getItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}` , 'string')
                const currentAccountKey = localStorage.getItem(
                  process.env.REACT_APP_STORAGE__ACCOUNT_KEY
                );

                // ! CREATE GA EVENT
                GA.GAevent('login', {
                  method: 'Email',
                });

                if (
                  typeof currentAccountKey !== 'undefined' &&
                  currentAccountKey !== null
                ) {
                  // account key in storage is not the default account and for the current user
                  if (
                    result.accounts.findIndex(
                      (x) => x.id === currentAccountKey && x.is_default === true
                    ) < 0
                  ) {
                    let defaultAccount = result.accounts.find(
                      (x) => x.is_default === true
                    );
                    if (
                      typeof defaultAccount === 'undefined' ||
                      defaultAccount === null
                    )
                      defaultAccount = result.accounts[0];
                    // save storage : current account id
                    this.context.getCurrentAccountData();
                    // this.context.setCurrentAccountData(defaultAccount);
                    setItemStorage(
                      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
                      defaultAccount.id,
                      'string'
                    );
                  }
                } else {
                  // Get the default account -> select it as the current account for the current user
                  let defaultAccount = result.accounts.find(
                    (x) => x.is_default === true
                  );
                  if (
                    typeof defaultAccount === 'undefined' ||
                    defaultAccount === null
                  )
                    defaultAccount = result.accounts[0];

                  // save storage : current account id
                  this.context.getCurrentAccountData();
                  // this.context.setCurrentAccountData(defaultAccount);
                  setItemStorage(
                    `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
                    defaultAccount.id,
                    'string'
                  );
                }
              }
            }
          );
        } else {
          this.setState({
            isLoading: false,
            signin_response: 'Something went wrong, please try again later.',
            signin_response_status: 'danger',
          });
        }
      })
      .catch((error) => {
        //console.log('error : ' , error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //console.log(error.response.data);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          this.setState({
            isLoading: false,
            signin_response: error.response.data.message,
            signin_response_status: 'danger',
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          if (
            typeof error.message === 'undefined' ||
            error.message === null ||
            error.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              signin_response:
                'An error occurred while signing to your account, please try again later',
              signin_response_status: 'danger',
            });
          }
        }
      });
  };

  componentDidMount() {
    verifySession()
      .then((token) => {
        this.setState({
          sessionToken: token,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        this.context.logout(); // update user session global state
      });
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    let submitButtonContent = (
      <span className="btn-inner load-spinner">
        <span>Log in</span>
      </span>
    );
    if (this.state.isLoading) {
      submitButtonContent = (
        <span className="btn-inner load-spinner">
          <span className="loader_spinner--content"></span>
          <span>Please wait</span>
        </span>
      );
    }

    const {
      signin_email_start_tapping,
      signin_password_start_tapping,
      signin_email_validate,
      signin_password_validate,
    } = this.state;
    //console.log(this.context);

    if (
      //(typeof this.context.isAuth!=='undefined' && this.context.isAuth === true )||
      this.state.sessionToken !== null &&
      typeof this.context.connectedUserData !== 'undefined' &&
      this.context.connectedUserData !== null
    ) {
      // redirect to dashboard home page
      return <Redirect to="/" />;
      // return (
      // 	<div className >
      // 		<div >Connected</div>
      // 		<button onClick={this.handleSignout} >Sign out</button>
      // 	</div>
      // );
    }

    return (
      <>
        <Helmet>
          <title>Signin | Odown</title>
          <meta
            name="description"
            content="Be the first to know about your website issues before your customers do with real-time website monitoring combined with instant alerts via email, Slack, Webhooks or SMS."
          />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://app.odown.io/signin" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Signin | Odown" />
          <meta
            property="og:description"
            content="Be the first to know about your website issues before your customers do with real-time website monitoring combined with instant alerts via email, Slack, Webhooks or SMS."
          />
          <meta
            property="og:image"
            content="https://app.odown.io/general-image-og--home-page.jpg"
          />

          {/* <!-- Twitter Meta Tags -- /> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="app.odown.io" />
          <meta property="twitter:url" content="https://app.odown.io/signin" />
          <meta name="twitter:title" content="Signin | Odown" />
          <meta
            name="twitter:description"
            content="Be the first to know about your website issues before your customers do with real-time website monitoring combined with instant alerts via email, Slack, Webhooks or SMS."
          />
          <meta
            name="twitter:image"
            content="https://app.odown.io/general-image-og--home-page.jpg"
          />
        </Helmet>
        <div className="layout__session layout__form--login bg-gris">
          <div className="layout__session--container">
            <div className="logo">
              <Logo />
            </div>
            <h1>Log in </h1>
            <form method="POST" action="" onSubmit={this.handleSubmit}>
              <div className="form-container">
                <div
                  className={`response-group ${
                    this.state.signin_response_status
                  } message ${this.state.signin_response === '' ? 'hide' : ''}`}
                >
                  <div className="">{this.state.signin_response}</div>
                </div>
                <div className="field-group">
                  <label htmlFor="" className="fluid">
                    Email
                  </label>
                  <input
                    type="email"
                    className={`textField fluid ${
                      signin_email_start_tapping === true &&
                      signin_email_validate === false
                        ? 'error'
                        : ''
                    }`}
                    placeholder={this.state.signin_email_placeholder}
                    value={this.state.signin_email}
                    onChange={this.handleChange_signinEmail}
                  />
                </div>
                <div className="field-group">
                  <label htmlFor="" className="fluid">
                    Password
                  </label>
                  <input
                    type="password"
                    className={`textField fluid ${
                      signin_password_start_tapping === true &&
                      signin_password_validate === false
                        ? 'error'
                        : ''
                    }`}
                    placeholder={this.state.signin_password_placeholder}
                    value={this.state.signin_password}
                    onChange={this.handleChange_signinPassword}
                  />
                </div>
                <div className="field-group">
                  <a href="/forgot-password">Forgot Password</a>
                </div>
                <div className="field-group submit-fields-group fluid">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={
                      !(
                        this.state.signin_email_validate &&
                        this.state.signin_password_validate
                      )
                    }
                  >
                    {submitButtonContent}
                  </button>
                </div>
              </div>
            </form>
            <div className="other-sign-block">
              <label htmlFor="">Don’t have an odown account?</label>
              <Link to="/signup">Sign up for free</Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
